
import brändisuunnittelu from '../../../assets/images/brändisuunnittelu.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './Branditarina.css'


function Branditarina() {
    return (
        <Container fluid id='tarina'>
            <Row>
            <Col className="d-lg-none d-flex justify-content-center p-0">
                    <Image
                        src={brändisuunnittelu}
                        className="header-image-branditarina"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="left-content-branditarina">

                    <h2 className='branditarina-h2 d-lg-flex d-none'>Brändihuvilan tarina</h2>
                    <div className='branditarina-text d-lg-flex flex-column d-none'>
                        <p>Kahvi, aurinko, terassi ja intohimo. Näistä tekijöistä syntyi Brändihuvila - uuden aikakauden bränditoimisto.</p>
                        <p>Kuulimme usein puhuttavan, etteivät suomalaiset osaa brändätä. Toista se on naapurimaissa, jossa osataan vedota tunteisiin ja rakentaa merkityksellisiä bränditarinoita. Niinpä ajattelimme, että muutetaan se. Muutetaan suomalaisten (yritysten) käsitys brändäyksestä, ja tehdään suomalaisista brändäyksen asiantuntijoita.</p>
                        <p><strong>Ja se on asia, jonka eteen työskentelemme joka ikinen päivä.</strong> (Tai ainakin kaikkina niinä päivinä, kun emme ole lomalla)</p>
                        <p>Brändi ei ole meille pelkkä logo tai se, miltä brändi näyttää. Brändit muuttavat maailmaa, ja ennen kaikkea: ne luovat oman maailmansa. Maailman, johon niin asiakkaat, yhteistyökumppanit kuin työntekijätkin haluavat kuulua. Hyvin tehty brändityö luo selkeyttä ja merkityksellisyyttä sekä herättää innostusta ja halua sitoutua. Siis kaikkea sitä, mitä nykyajan maailmassa tarvitaan.</p>
                        <p>Teemme brändityötä, joka ei jää pintapuoliseksi. Olemme paikka, jossa on aikaa pohtia asioita syvällisemmin. Juoda toinen kuppi kahvia, jotta mitään ei jää huomiomatta. Emme vain suosittele tai kerro miten asiat kannattaisi tehdä. Sen sijaan opettelemme ja oivallutamme, jotta brändityömme aikana teistäkin kuoriutuu brändäyksen asiantuntijoita.</p>
                        <p>Sillä näin toimii uuden aikakauden bränditoimisto.</p>
                    </div>
                </Col>
                <Col className="d-lg-flex d-none justify-content-end p-0">
                    <Image
                        src={brändisuunnittelu}
                        className="header-image-branditarina"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column justify-content-center align-items-center">
                    <h2 className="branditarina-h2 d-lg-none d-flex my-3">Brändihuvilan tarina</h2>
                    <div className="branditarina-text d-lg-none">
                        <p>Kahvi, aurinko, terassi ja intohimo. Näistä tekijöistä syntyi Brändihuvila - uuden aikakauden bränditoimisto.</p>
                        <p>Kuulimme usein puhuttavan, etteivät suomalaiset osaa brändätä. Toista se on naapurimaissa, jossa osataan vedota tunteisiin ja rakentaa merkityksellisiä bränditarinoita. Niinpä ajattelimme, että muutetaan se. Muutetaan suomalaisten (yritysten) käsitys brändäyksestä, ja tehdään suomalaisista brändäyksen asiantuntijoita.</p>
                        <p><strong>Ja se on asia, jonka eteen työskentelemme joka ikinen päivä.</strong> (Tai ainakin kaikkina niinä päivinä, kun emme ole lomalla)</p>
                        <p>Brändi ei ole meille pelkkä logo tai se, miltä brändi näyttää. Brändit muuttavat maailmaa, ja ennen kaikkea: ne luovat oman maailmansa. Maailman, johon niin asiakkaat, yhteistyökumppanit kuin työntekijätkin haluavat kuulua. Hyvin tehty brändityö luo selkeyttä ja merkityksellisyyttä sekä herättää innostusta ja halua sitoutua. Siis kaikkea sitä, mitä nykyajan maailmassa tarvitaan.</p>
                        <p>Teemme brändityötä, joka ei jää pintapuoliseksi. Olemme paikka, jossa on aikaa pohtia asioita syvällisemmin. Juoda toinen kuppi kahvia, jotta mitään ei jää huomiomatta. Emme vain suosittele tai kerro miten asiat kannattaisi tehdä. Sen sijaan opettelemme ja oivallutamme, jotta brändityömme aikana teistäkin kuoriutuu brändäyksen asiantuntijoita.</p>
                        <p>Sillä näin toimii uuden aikakauden bränditoimisto.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Branditarina