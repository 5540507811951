import Brandilupaukset from '../../components/ui/Brandilupaukset/Brandilupaukset'
import Brandimatka from '../../components/ui/Brandimatka/Brandimatka'
import Brandistrategi from '../../components/ui/Brandistrategi/Brandistrategi'
import Branditarina from '../../components/ui/Branditarina/Branditarina'
import Contact from '../../components/ui/Contact/Contact'
import Footer from '../../components/ui/Footer/Footer'
import Form from '../../components/ui/Form/Form'
import Header from '../../components/ui/Header/Header'
import Hero from '../../components/ui/Hero/Hero'
import NavBar from '../../components/ui/NavBar/NavBar'
import PageSplitter from '../../components/ui/PageSplitter/PageSplitter'
import Services from '../../components/ui/Services/Services'
import TopBar from '../../components/ui/TopBar/TopBar'
import './Home.css'
import Feedback from '../../components/ui/Feedback/Feedback'
import Brandiuudistus from '../../components/ui/Brandiuudistus/Brandiuudistus'
import Brandipodi from '../../components/ui/Brandipodi/Brandipodi'
import Brandiomatoimi from '../../components/ui/Brandiomatoimi/Brandiomatoimi'



function Home() {
    return (
        <div className='fade-in-container'>
            <TopBar />
            <NavBar />
            <Header />
            <Hero />
            <Brandiuudistus />
            <Services />
            <Brandimatka />
            <Brandiomatoimi />
            <Brandistrategi />
            <div className="d-flex justify-content-center align-items-center">
                <PageSplitter />
            </div>
            <Branditarina />
            <Feedback />
            <Brandilupaukset />
            <Brandipodi />
            <Contact />
            <Form />
            <Footer />
        </div>
    )
}

export default Home
