import { Row, Col, Container } from "react-bootstrap"
import ServiceBlock from "./ServiceBlock"
import './Services.css'
import strategi from '../../../assets/images/strategi.jpg'
import brandimatka from '../../../assets/images/brändimatka.jpg'
import koulutukset from '../../../assets/images/koulutukset.jpg'
import brändikonsultti from '../../../assets/images/brändikonsultti.jpg'








function Services() {
    return (
        <section id="palvelumme">
            <Container fluid>
                <Row className="d-flex align-items-center">
                    <h2 className="services-h2 text-center d-lg-none p-0 m-0">Palvelumme</h2>
                    <Col xs={6} className="d-none d-lg-flex justify-content-start text-center">
                        <h2 className="services-h2">Palvelumme</h2>
                    </Col>
                    <Col xs={6} className="text-center justify-content-center d-none d-lg-flex">
                        <span className="quotation-mark">❝</span>
                        <p className="mb-0 small-companies">Pienille yrityksille, joista on <br />kasvamassa jotain suurta</p>
                    </Col>
                </Row>
                <div className="container my-2">
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xs={12} md={12} lg={3} className="mb-4 d-flex align-items-center justify-content-center">
                            <ServiceBlock
                                image={brandimatka}
                                title="Brändimatka"
                                description={
                                    <>
                                        Kahden kuukauden mittainen brändiprojekti, joka koostuu useista brändityöpajoista.
                                        Määränpäänämme on <strong>poikkeuksellisen kattava brändikäsikirja,</strong> jonka avulla yrityksenne
                                        brändin johtaminen on erityisen selkeää - ja mielekästä.
                                    </>
                                }
                                price="3000€/kk + alv 25,5%"
                                buttonText="Lue lisää"
                                imagePosition="center"
                                link="#brandimatka"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="mb-4 d-flex align-items-center justify-content-center omatoimi">
                            <ServiceBlock
                                image={brändikonsultti}
                                title="Brändimatka - Omatoimi"
                                description={
                                    <>
                                        <strong>Täysin itsenäisesti toteuttava</strong> Brändimatka, jossa saat valmiin Canvaan suunnitellun brändikäsikirja -pohjan sekä opastusvideon.
                                        Näiden avulla voit suunnitella oman yrityksesi brändikäsikirjan itse oman aikataulusi puitteissa - mutta asiantuntijan opastuksella.
                                    </>
                                }
                                imagePosition="50% 70%"
                                price="79,90€ + alv 25,5%"
                                buttonText="Lue lisää"
                                link="#brandiomatoimi"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="mb-4 d-flex align-items-center justify-content-center">
                            <ServiceBlock
                                image={strategi}
                                title="Vieraileva brändistrategi"
                                description={
                                    <>
                                        Palvelu, jonka avulla saatte oman brändistrategin yrityksenne käyttöön
                                        juuri niin pitkäksi aikaa, kun teillä on tarvetta. <strong>Yrityksenne oma, vieraileva
                                            brändistrategi</strong> auttaa kehittämään ja johtamaan yrityksenne brändiä kohti haluttua suuntaa.
                                    </>
                                }
                                price="1500€/kk + alv 25,5%"
                                buttonText="Lue lisää"
                                link="#brandistrategi"
                                imagePosition="top"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="mb-4 d-flex align-items-center justify-content-center">
                            <ServiceBlock
                                image={koulutukset}
                                title="Brändikoulutukset ja -luennot"
                                description={
                                    <>
                                        Tarvitsetko kouluttajan tai puhujan tapahtumaanne? Toteutamme
                                        <strong> yritysbrändäykseen liittyviä koulutuksia/luentoja</strong> etänä sekä paikan päällä ympäri Suomen.
                                        Koulutuksen sisältö sovitaan aina tapauskohtaisesti
                                        toiveidesi mukaan.
                                    </>
                                    }
                                imagePosition="top"
                                price="Hinta sovitaan erikseen."
                                buttonText="Pyydä tarjous"
                                link="#ota-yhteytta"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Services