import { Col, Container, Row, Image } from "react-bootstrap"
import { HashLink } from "react-router-hash-link"
import brändikonsultti from '../../../assets/images/brändikonsultti.jpg'
import CollapsibleText from "../CollapsibleText/CollapsibleText"
import './Brandiomatoimi.css'





function Brandiomatoimi() {

    return (
        <Container id="brandiomatoimi" fluid>
            <Row>
                <Col className='d-flex d-lg-none justify-content-center align-items-center'>
                    <Image
                        src={brändikonsultti}
                        alt="Brändikonsultti"
                        className="omatoimi2-image"

                    />
                </Col>
            </Row>
            <Row>
                <Col className="left-content-brandiomatoimi d-none d-lg-flex p-0 m-0">
                    <Image
                        src={brändikonsultti}
                        alt="Brändikonsultti"
                        className="omatoimi2-image"
                    />
                </Col>
                <Col className="right-content-brandiomatoimi d-flex flex-column align-items-lg-end text-lg-end align-items-center text-center">
                    <h2 className="brandiomatoimi-h2 mt-1">Brändimatka - Omatoimi</h2>
                    <div className="d-flex flex-row">
                        <p className="brandiomatoimi-price-text">Hinta 79,90€ + alv 25,5%</p>
                    </div>


                    <p className="brandiomatoimi-text-p">Selkeä ja vetovoimainen brändi vetää puoleensa juuri oikeita asiakkaita ja sitouttaa heidät käyttämään juuri sinun yrityksesi tuotteita tai palveluita jatkossakin. Olet ehkä ajatellut, että brändäys on vaikeaa, kallista ja oikeastaan vain isojen yritysten juttu. Me haluamme tehdä brändäyksestä selkeää ja yksinkertaista sekä yksinyrittäjien budjettiin sopivaa, ja siksi loimme palvelun, jonka avulla sinä voit lähteä rakentamaan oman yrityksesi brändiä haluamaasi suuntaan itsenäisesti, mutta asiantuntijan opastuksella. Jos kaipaat selkeyttä omaan yritystoimintaasi, olet oikeassa paikassa.</p>


                    <p className="brandiomatoimi-read-more-text">Tästä pääset lukemaan lisää:</p>
                    <div className="collapsible-container-brandiomatoimi align-items-lg-start align-items-center">
                        <CollapsibleText buttonText="Pitääkö yksinyrittäjän miettiä brändiä?" content="
Brändi on se tunne ja mielikuva, joka yrityksestäsi rakentuu ihmisten mielessä. Yritykselläsi on siis jo brändi, vaikka et olisi tehnyt sen eteen vielä mitään. 
<Br/>
<Br/>
Hyvin tehdyllä brändityöllä voit vaikuttaa merkittävästi siihen, minkälainen mielikuva 
yrityksestäsi ihmisten mieleen muodostuu ja se puolestaan vaikuttaa suoraan siihen, 
kuinka paljon tuotteitasi/palveluitasi halutaan käyttää ja miten niiden käyttöön sitoudutaan. 
<Br/>
<Br/>
Siispä brändin rakentaminen ja selkeyttäminen on oikeastaan yksinyrittäjien valttikortti: ne yrittäjät, 
jotka oivaltavat keskittyä brändin rakentamiseen, erottuvat kilpailijoista ja löytävät juuri oikeat asiakkaat 
luokseen. Ja kaiken lisäksi yritystoiminta on itselle selkeää ja motivoivaa!
" />
                        <CollapsibleText buttonText="Mitä palveluun sisältyy?" content="
                        Brändimatka - Omatoimen aikana opit uutta brändin rakentamisesta sekä oivallat uusia näkökulmia yritystoiminnastasi. 
                        Matkan jälkeen tekemisesi on selkeää, innostavaa ja johdonmukaista.
                        <Br/>
                        <Br/>
                        Palvelu sisältää:
                        <Br/>
                        <Br/>
                        -<strong>Valmis Canvaan suunniteltu brändikäsikirja -pohja,</strong> joka sisältää:
                        <Br/>
                        <Br/>
                        <li>Kohderyhmä-analyysi</li>
                        <li>Kilpailija-analyysi</li>
                        <li>⁠Erottautumistekijät</li>
                        <li>Arvomme</li>
                        <li>⁠Visio ja missio</li>
                        <li>⁠Bränditarina</li>
                        <li>⁠Brändin kasvot (esim. Yrittäjän henkilöbrändi)</li>
                        <li>Brändiääni</li>
                        <li>⁠Brändin visuaalinen maailma</li>
                        <Br/>
                        <Br/>
                        -<strong>Opastusvideo,</strong> jossa käydään läpi kohta kohdalta brändikäsikirjan täyttäminen Canvaan.
                        <Br/>
                        <Br/>
                        <i>Canvan käyttäminen on maksutonta, mutta kirjautuminen vaaditaan.</i>" />
                        <CollapsibleText buttonText="Paljonko tarvitsen tähän aikaa?" content="
                        Suosittelemme katsomaan opastusvideota ja täyttämään brändikäsikirjaa yksi osio kerrallaan. 
                        Tekemistä kannattaa jakaa eri päiville ja viikoille, jotta ehdit prosessoida uutta tietoa ja tehdä oivalluksia 
                        yrityksesi toiminnasta myös osioiden välissä.
                        <Br/>
                        <Br/>
                        Osioita on yhteensä 8, ja yhden osion täyttämiseen arvioitu aika on noin 2h. 
                        Käytetty aika riippuu täysin siitä, kuinka syvällisesti haluat paneutua eri osioihin. 
                        Jos esimerkiksi aikaa käytetään 2x2h per viikko, kokonaisuudessaan brändikäsikirjan täyttäminen kestää noin 4 viikkoa.
                        " />
                        <CollapsibleText buttonText="Miten aloitan?" content="
                        Brändimatka - Omatoimi -palvelun voit ostaa verkkokaupasta. Oston jälkeen saat sähköpostiisi vahvistuksen, josta löydät linkin Canva -pohjaan sekä opastusvideoon. Voit siis aloittaa tekemisen heti oston jälkeen.
                        <Br/>
                        <Br/>
                        Palvelun hinta on 79,90€ + alv 25,5% ja palvelu maksetaan kokonaisuudessaan oston yhteydessä.
                        " />
                        <CollapsibleText buttonText="Sopiiko tämä minulle?" content="
                        Brändimatka - Omatoimi on suunniteltu sinulle jos..
                        <Br/>
                        <Br/>
                        <li>Olet aloittamassa uutta yritystä tai olet toiminut yrittäjänä jo pidemmän ajan. Brändityö ei katso yrityksesi ikää, vaan siitä on hyötyä kaiken ikäisille yrityksille - ja yrittäjille.</li><Br/>
                        <li>⁠Haluat rakentaa omalle toiminnallesi vahvan yritys-, tuote- tai henkilöbrändin. Videon alussa selkeytämme mitä brändiä olet rakentamassa ja videon aikana huomioimme niin yritys-, tuote- kuin henkilöbrändien erityispiirteet.</li><Br/>
                        <li>⁠Haluat erottua kilpailijoista ja löytää juuri itsellesi sopivimmat asiakkaat. Jos et ole tehnyt kilpailija- tai asiakasanalyysia aiemmin, nyt pääset tekemään myös nämä yritystoiminnan peruspilarit kuntoon.</li><Br/>
                        <li>⁠Sinulla on käytössä tietokone Canva -pohjan täyttämistä varten. Canvan käyttö on yksinkertaista ja näet videolta miten valmista pohjaa Canvassa täytetään.</li><Br/>
                        <li>⁠Sinulla ei ole juuri nyt rahaa, aikaa tai kiinnostusta brändiasiantuntijan kanssa henkilökohtaisesti työskentelemiseen, mutta haluat oppia lisää brändin rakentamisesta yritystoimintasi kehittämiseksi.</li><Br/>
                        <li>⁠Et ole aiemmin osannut sanoittaa sitä, mikä tekee juuri sinun yrityksestäsi uniikin ja erinomaisen vaihtoehdon asiakkaillesi.</li>
                        <Br/>
                        Mikäli mietit vielä sopiiko Brändimatka - Omatoimi sinulle, laitathan meille viestiä sähköpostitse tai Instagramissa, niin tsekataan tilanteesi yhdessä. > <strong><a href='mailto:jenni@brandihuvila.fi'>jenni@brandihuvila.fi</a></strong>" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100 mt-5">
                        <a href="https://holvi.com/shop/brandihuvila/product/b9ae6c7e7c45ddf0c13d1e0c1cb12264/" target="_blank" rel="noopener noreferrer"
                            className="btn btn-primary brandiomatoimi-button brandiomatoimi-button1" style={{ color: '#38b6ff' }}>Osta tästä
                        </a>
                        <HashLink to="#tarina" className="btn btn-primary brandiomatoimi-button">Tutustu lisää Brändihuvilaan</HashLink>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default Brandiomatoimi