import { Col, Container, Row, Image } from "react-bootstrap"
import './Brandimatka.css'
import brändimatka from '../../../assets/images/brändimatka.jpg'
import CollapsibleText from "../CollapsibleText/CollapsibleText"
import { HashLink } from "react-router-hash-link"





function Brandimatka() {

    return (
        <Container id="brandimatka" fluid className="brandiMatka">
            <Row className="mt-4">
                <Col className='d-flex d-lg-none justify-content-center align-items-center'>
                    <Image
                        src={brändimatka}
                        alt="Brändihuvila"
                        className="brandiMatka-image"

                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column align-items-center text-center align-items-lg-start text-lg-start left-content-brandi-matka">
                    <h2 className="brandiMatka-text-h2 mt-1">Brändimatka</h2>
                    <p className="brandiMatka-price-text">Hinta 6000€ + alv 25,5%, kesto 2kk</p>
                    <p className="brandiMatka-text-p">Yrityksenne brändi on koko liiketoimintanne ydin. Usein brändityössä keskitytään siihen, miltä brändi näyttää, mutta me teemme brändityötä, joka ei jää pintapuoliseksi. Yrityksenne brändi vaikuttaa asiakkaiden lisäksi myös yrityksenne työntekijöihin, yhteistyökumppaneihin sekä mahdollisiin sijoittajiin. Brändimatkan aikana opitte johtamaan ja kehittämään brändiänne kokonaisvaltaisesti.</p>
                    <p className="read-more-text">Tästä pääset lukemaan lisää:</p>
                    <div className="collapsible-container align-items-lg-start align-items-center">
                        <CollapsibleText buttonText="Mitä pakettiin sisältyy?" content="Brändityö ei ole ainoastaan markkinointitiimin juttu, sillä brändi vaikuttaa ihan jokaiseen työntekijäänne, ja oikeastaan: se syntyy työntekijöidenne käsissä. Siksi Brändimatkaan osallistetaan aina koko yrityksen henkilöstö. 

<Br/><Br/>Brändimatka sisältää:<Br/><Br/>

<ul>
    <li>Brändityöpajat 4x4h, Teams-yhteydellä</li>
    <li>Lopputuloksen läpikäynti 2h, Teams-yhteydellä</li>
    <li>Yrityksen markkinointiviestinnän seuranta ja brändituki sähköpostitse 2kk ajan</li>
    <li>Visuaalisen ilmeen kartoitus</li>
    <li>Brändikäsikirja, joka sisältää:</li>
    <ul>
        <li>Kohderyhmäanalyysi: unelma-asiakkaat ja ostajapersoonat</li>
        <li>Kilpailija-analyysi: erottautumistekijät ja brändipositio</li>
        <li>Bränditarina lyhyt ja pitkä versio</li>
        <li>Toimitusjohtajan henkilöbrändi tai muut yrityksen kasvot</li>
        <li>Arvomme</li>
        <li>Lupaus</li>
        <li>Visio</li>
        <li>Missio</li>
        <li>Asiakaskokemus</li>
        <li>Brändin ydinviestit</li>
        <li>Brändi-identiteetti: brändipersoona, brändiääni</li>
        <li>Brändin näkyminen sidosryhmille (työntekijät, yhteistyökumppanit, tarvittaessa sijoittajat ja media)</li>
    </ul>
</ul>

Huom! Brändikäsikirja ei sisällä logoa, brändivärejä tai graafista suunnittelua. Visuaaliseen ilmeen kartoitus sisältyy aina hintaan ja mikäli todetaan, että päivittämiselle on tarvetta, tehdään visuaalisen ilmeen päivittämisestä aina tarjous teille erikseen." />

                        <CollapsibleText buttonText="Kuinka kauan yhteistyö kestää?" content="Yhteistyömme kestää kaksi kalenterikuukautta. Seuraava mahdollinen <strong>aloitus on 1.4.2025.</strong><Br/><Br/>
                        Vuonna 2025 asiakaspaikkoja on seuraavasti:<Br/><Br/>
                        <strong>Aloitus 1.4.</strong> 1 paikka<Br/>
                        <strong>Aloitus 1.5.</strong> 1 paikka<Br/>
                        <strong>Aloitus 1.8.</strong> 2 paikkaa<Br/>
                        <strong>Aloitus 1.10.</strong> 2 paikkaa<Br/>
                        " />
                        <CollapsibleText buttonText="Kuinka paljon projekti kustantaa?" content="Projektin hinta on 6000€ + alv 25,5%. Laskutus toteutetaan valintanne mukaan yhdessä tai kahdessa erässä - aina jälkikäteen. Ensin työ, sitten laskutus.<Br/><Br/>

Tarjoamme tyytyväisyystakuun projektille ensimmäisen kuukauden ajan. Jos siis projektin ensimmäisen kuukauden aikana ette ole tyytyväisiä palveluun, voidaan projekti aina peruuttaa ja tällöin laskutamme teiltä 0€." />
                        <CollapsibleText buttonText="Miten yhteistyömme etenee?" content="Kun olet meihin yhteydessä lomakkeen kautta tai sähköpostitse, etenemme näin:<Br/>
<Br/>
1. Maksuton kartoitustapaaminen Teams-yhteydellä<Br/>
2. Yhteistyösopimuksen allekirjoittaminen sähköisesti<Br/>
3. Projektin suunnitelman ja aikataulun hyväksyminen sähköpostitse<Br/>
4. Toteutetaan suunnitelma!<Br/>
<Br/>
Projektin viimeisellä tapaamisella käymme läpi selkeän ja poikkeuksellisen kattavan brändikäsikirjan, jonka avulla brändinne johtaminen on tulevaisuudessa selkeää -  ja mielekästä." />
                        <CollapsibleText buttonText="Sopiiko Brändimatka meille?" content="
                        Brändimatka on suunniteltu teidän yritykselle jos..
                        <Br/>
                        <Br/>
                        <li>Olette luomassa täysin uutta brändiä tai haluatte uudistaa tai selkeyttää jo olemassa olevaa brändiänne. Brändityössä ei ole merkitystä yrityksen iällä, sillä brändin kirkastamisesta hyötyvät kaiken ikäiset yritykset.</li><Br/>
                        <li>Teillä ei ole brändikäsikirjaa tai brändistrategiaa, josta koette aidosti saavanne hyötyä käytännön tekemiseen.</li><Br/>
                        <li>Teidän on vaikeaa erottua kilpailijoista ja sanoittaa oman yrityksenne erottautumistekijöitä. Erottuva, vahva ja vetovoimainen brändi on jotain, mitä kilpailijat eivät voi teiltä kopioida.</li><Br/>
                        <li>⁠Teidän on vaikeaa löytää ja sitouttaa työntekijöitä yritykseenne. Hyvin tehty brändityö innostaa, sitouttaa ja luo merkityksellisyyden tunteita myös henkilöstölle.</li><Br/>
                        <li>Haluatte aloittaa selkeän brändiprojektin, jonka aikana opitte itse johtamaan ja kehittämään yrityksenne brändiä tulevaisuudessa.</li>
                        " />
                        <Row className="mt-5">
                            <Col style={{ marginBottom: '50px' }}>
                                <HashLink to="#ota-yhteytta" className="btn btn-primary brandi-matka-button-mobile d-lg-none">Olen kiinnostunut</HashLink></Col>
                            <Col> <HashLink to="#tarina" className="btn btn-primary brandi-matka-button-mobile d-lg-none">Tutustu lisää Brändihuvilaan</HashLink></Col>
                        </Row>
                    </div>
                </Col>
                <Col className="d-none d-lg-flex flex-column justify-content-end p-0">
                    <Image
                        src={brändimatka}
                        alt="Brandimatka"
                        className="brandiMatka-image"

                    />
                    <Col style={{ marginTop: '70px' }}>
                        <HashLink to="#ota-yhteytta" className="btn btn-primary brandi-matka-button" style={{ marginRight: '50px' }}>Olen kiinnostunut</HashLink>
                        <HashLink to="#tarina" className="btn btn-primary brandi-matka-button">Tutustu lisää Brändihuvilaan</HashLink>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}

export default Brandimatka