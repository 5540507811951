import './Statement.css'

function Statement() {

    return (
        <div className='global'>
            <h1>Tietosuojaseloste</h1>
            <p>Tämä on Brändihuvila Oy:n EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen tietosuojaseloste. Laadittu 06.10.2024. Viimeisin muutos 13.2.2025.</p>

            <h2>1. Rekisterinpitäjä</h2>
            <p>Brändihuvila Oy<br />3477040-9<br />Valtatie 75 A 8, 90500 Oulu</p>

            <h2>2. Rekisteristä vastaava yhteyshenkilö</h2>
            <p>Jenni Kauppila<br />jenni@brandihuvila.fi</p>

            <h2>3. Rekisterin nimi ja siihen liittyvät sivustot</h2>
            <p>Brändihuvila Oy asiakasrekisteri ja markkinointirekisteri, www.brandihuvila.fi</p>

            <h2>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h2>
            <p>Käsittelemme henkilötietoja seuraaviin tarkoituksiin:</p>
            <ul>
                <li>Asiakassuhteen hoitaminen ja hallinnointi</li>
                <li>Palveluidemme tarjoaminen ja toteuttaminen</li>
                <li>Laskutus ja perintä</li>
                <li>Markkinointi ja viestintä asiakkaiden suuntaan</li>
                <li>Sähköinen markkinointi</li>
            </ul>
            <p>Oikeusperusteet henkilötietojen käsittelylle:</p>
            <ul>
                <li>Sopimuksen täytäntöönpano (esim. asiakassuhde ja palveluiden tuottaminen)</li>
                <li>Lakisääteiset velvoitteet (esim. kirjanpitolaki)</li>
                <li>Suostumus (esim. suoramarkkinointi)</li>
                <li>Rekisterinpitäjän oikeutettu etu (esim. asiakassuhteen hoito ja markkinointi)</li>
            </ul>

            <h2>5. Rekisterin tietosisältö</h2>
            <p className='text-decoration-underline'>Asiakasrekisteriin tallennettavia tietoja ovat:</p>
            <ul>
                <li>Nimi</li>
                <li>Yhteystiedot (sähköposti, puhelinnumero, osoite)</li>
                <li>Yrityksen tiedot (esim. yrityksen nimi, y-tunnus, laskutustiedot)</li>
                <li>Sopimustiedot ja yhteydenotot</li>
                <li>Mahdolliset muut asiakkaan itse toimittamat, asiakassuhteeseen liittyvät tiedot</li>
            </ul>
            <p className='text-decoration-underline'>Markkinointirekisteriin tallennettavia tietoja ovat:</p>
            <ul>
                <li>Sähköpostiosoite</li>
            </ul>

            <p>Uutiskirjeen tilaajan tiedot rekisteriin saadaan, kun henkilö liittyy uutiskirjelistalle lomakkeella, osallistuu webinaariin tai ostaa jonkin yrityksen tarjoaman digitaalisen tuotteen verkkokaupasta.</p>

            <p>Verkkosivuston vierailijoiden IP-osoitteita ja palvelun toiminnoille välttämättömiä evästeitä käsitellään oikeutetun edun perusteella mm. tietoturvasta huolehtimiseksi ja sivuston vierailijoiden tilastotietojen keruuta varten niissä tapauksissa, kun niiden voidaan katsoa olevan henkilötietoja. Kolmansien osapuolten evästeille pyydetään tarvittaessa suostumus erikseen.</p>

            <p>Säilytämme henkilötietoja vain niin kauan kuin on tarpeen tässä tietosuojaselosteessa määriteltyjen tarkoitusten toteuttamiseksi ja lakisääteisten velvoitteiden täyttämiseksi.
                Asiakassuhteen päätyttyä tiedot poistetaan kohtuullisen ajan kuluessa, ellei laki vaadi niiden säilyttämistä pidempään (esim. kirjanpitoaineisto). Markkinointia varten kerätyt tiedot poistetaan ainoastaan kun rekisteröity peruuttaa suostumuksen tai kieltää suoramarkkinoinnin.</p>

            <h2>6. Säännönmukaiset tietolähteet</h2>
            <p>Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.
            Yritysten ja muiden organisaatioiden yhteyshenkilöiden tietoja voidaan kerätä myös julkisista lähteistä kuten verkkosivuilta, hakemistopalveluista ja muilta yrityksiltä.</p>

            <h2>7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h2>
            <p>Emme luovuta henkilötietoja kolmansille osapuolille, ellei se ole tarpeen palveluiden toteuttamiseksi, lain vaatimana tai asiakkaan suostumuksella.
            Voimme käyttää ulkopuolisia palveluntarjoajia (esim. laskutuspalvelut, IT-tuki), jotka käsittelevät henkilötietoja. Riippuen siitä, minkä maksupalveluntarjoajan valitset tilausprosessissa, välitämme tätä tarkoitusta varten kerätyt maksutiedot maksun suorittamisesta vastaavalle luottolaitokselle ja tarvittaessa maksupalveluntarjoajille, joita käytämme maksujen käsittelemiseksi. Nämä palveluntarjoajat sitoutuvat noudattamaan voimassa olevaa tietosuojalainsäädäntöä. Emme pääsääntöisesti siirrä henkilötietoja EU
            tai ETA ulkopuolelle. Jos tietoja siirretään, varmistamme asianmukaiset suojatoimet (esim. Euroopan komission mallisopimuslausekkeet).</p>
            <p>Verkkosivuston kävijätietoja kerää:</p>
            <p>Google Analytics</p>
            <p>Cookiebot: https://www.cookiebot.com evästeiden keräämiseksi.</p>
            <p>Smaily: https://smaily.com</p>

            <h2>8. Rekisterin suojauksen periaatteet</h2>
            <p>Henkilötietoja käsitellään huolellisesti ja asianmukaisesti suojatuissa järjestelmissä. Käytämme teknisiä ja organisatorisia toimenpiteitä suojataksemme henkilötietoja luvattomalta pääsyltä, muokkaamiselta tai poistamiselta.</p>

            <h2>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h2>
            <p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää sähköpostitse rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>

            <h2>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h2>
            <p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää sähköpostitse rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>

            <h2>11. Evästeet</h2>
            <p>Käytämme verkkosivuillamme niin sanottuja evästeitä tunnistaaksemme, että sama käyttäjä/internet-yhteyden omistaja käyttää palveluamme useaan kertaan. Evästeet ovat pieniä tekstitiedostoja, jotka internet-selaimesi tallentaa tietokoneellesi. Niiden avulla voimme optimoida Internet-sivustomme ja tarjouksemme tietosuoja-asetuksen artiklan 6 mukaisesti. Nämä ovat enimmäkseen niin sanottuja "istuntoevästeitä", jotka poistetaan uudelleen vierailun päätyttyä.</p>
            <p>Joissakin tapauksissa evästeet kuitenkin antavat tietoja, joiden avulla sinut voidaan tunnistaa automaattisesti. Tunnistaminen perustuu evästeisiin tallennettuun IP- osoitteeseen. Näin saatuja tietoja käytetään tarjoustemme optimointiin ja sivuston käytön helpottamiseen. Voit kieltää evästeiden käytön valitsemalla selaimesi asianmukaiset asetukset, mutta huomaa, että jos teet näin, et ehkä pysty käyttämään tämän verkkosivuston kaikkia toimintoja.</p>

            <h2>12. Uutiskirje</h2>
            <p>Tarjoamme verkkosivustollamme mahdollisuuden tilata uutiskirjeemme. Perustuen suostumukseesi Art. 6 Para. 1 lit. a yleisen tietosuoja-asetuksen mukaisesti tiedotamme sinulle tarjouksistamme säännöllisin väliajoin tällä uutiskirjeellä. Uutiskirjeemme vastaanottamiseen tarvitset voimassa olevan sähköpostiosoitteen. Tarkistamme antamasi sähköpostiosoitteen varmistaaksemme, että olet todella annetun sähköpostiosoitteen omistaja tai että omistajalla on oikeus vastaanottaa uutiskirje. Kun rekisteröidyt uutiskirjeeseemme, tallennamme IP-osoitteesi sekä rekisteröinnin päivämäärän ja kellonajan. Tämä toimii turvana siltä varalta, että kolmas osapuoli käyttää sähköpostiosoitettasi väärin ja tilaa uutiskirjeemme tietämättäsi. Emme kerää muita tietoja. Näin kerättyjä tietoja käytetään yksinomaan uutiskirjeemme vastaanottamiseen. Niitä ei luovuteta kolmansille osapuolille. Tällä tavoin kerättyjä tietoja ei myöskään verrata tietoihin, joita sivustomme muut osat saattavat kerätä. Voit peruuttaa uutiskirjeen tilauksen milloin tahansa. Löydät lisätietoja tästä jokaisesta yksittäisestä uutiskirjeestä.</p>

            <h2>13. Muutokset tietosuojaselosteeseen</h2>
            <p>Pidätämme oikeuden päivittää tätä tietosuojaselostetta tarpeen mukaan. Kaikki muutokset julkaistaan verkkosivuillamme, ja merkittävistä muutoksista ilmoitamme erikseen.</p>
        </div>
    )
}

export default Statement